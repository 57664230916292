.container-layout {
  position: relative;
}

.no-search {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  color: #6c7a8f;
  font-size: 14px;
}

.buttons {
  margin: 2px;
}

.form-group select {
  padding: 7px 40px 7px 12px;
  width: 100%;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 3px -2px #9098a9;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
  color: #5a667f;
}

.text-left {
  margin-top: 70px;
}

.card-header {
  font-size: 16px;
  color: #6c7a8f;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  height: 41px;
}

.card-body {
  background: #ffffff;
  font-size: 12px;
  font-weight: 500;
  color: #6c7a8f;
}

.react-bootstrap-table .table {
  color: #6c7a8f;
  font-size: 12px;
  font-weight: 500;
}

.react-bootstrap-table .form-control {
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  opacity: 1;
}

.react-bootstrap-table .filter-label {
  margin-left: 10px;
}

.card-body input {
  font-size: 14px;
  font-weight: 500;
  color: #6c7a8f;
}

.checkBox {
  font-size: 14px;
  color: #6c7a8f;
}

.checkBox input {
  color: #13597b;
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.checkBox input :hover {
  color: #13597b;
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.textFilter {
  display: flex;
  align-items: baseline;
}

.filter {
  font-size: 14px;
  font-weight: 500;
}

.filterDesc {
  padding-left: 5px;
  font-size: 12px;
  font-weight: 400;
}

.buttons {
  font-size: 12px;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 5px;
  border-style: none;
  opacity: 1;
  background: #307da2 0% 0% no-repeat padding-box;
}

.buttons:hover {
  color: #ffffff;
  font-size: 12px;
  background: #13597b 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border-style: none;
  opacity: 1;
}

.buttonExpand {
  float: right;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #6c7a8f;
  opacity: 1;
  background: transparent;
  border: none;
  text-decoration: none;
}

.buttonExpand:hover,
.buttonExpand:focus {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #6c7a8f;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  border: none;
  text-decoration: none;
  border-style: none;
}

.buttonClear {
  margin-left: 5px;
  font-size: 12px;
  color: #3d4e68;
  border-style: none;
  background: #ffffff00;
}

.buttonClear:hover {
  font-size: 12px;
  border-style: none;
  background: #d7d9da;
  color: #3d4e68;
}

.buttonMaisEmpresas {
  width: 100%;
  height: 40px;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  border-style: none;
  background: #307da2;
}

.buttonMaisEmpresas:not([disabled]):hover {
  font-size: 14px;
  border-style: none;
  background: #d7d9da;
  color: #3d4e68;
  font-weight: 500;
}

.buttonMaisEmpresas:disabled {
  width: 100%;
  background: #ffffff;
  color: #3d4e68;
  opacity: 0.6;
  cursor: initial;
}

@media screen and (max-width: 768px) {
  .top-bar-titles-pesquisa {
    margin-left: 0.2rem;
  }
}
