.card {
  margin: 5px;
}

.card-group:nth-last-child(-n+2){
  margin-top: 5px;
}

.top-bar-extrato {
  display: flex;
  position: fixed;
  background: #ffffff;
  height: 64px;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  left: 0;
  right: 0;
  border-style: none;
  top: 50px;
  z-index: 1;
}

.top-bar-titles-extrato {
  margin-left: 5rem;
}

.top-bar-extrato h3 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  color: #6c7a8f;
  opacity: 1;
}

.top-bar-extrato p {
  margin: 0%;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  color: #ff5811;
  opacity: 1;
}

.top-bar-search {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 280px;
}

.inputTextSearch {
  padding-left: 10px;
  height: 36px;
  width: 241px;
  color: #6c7a8f;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d3d5;
  border-radius: 0px 4px 4px 0px;
  border-left-style: none;
  opacity: 1;
}

.button-search {
  background: #ffffff;
  width: 39px;
  height: 36px;
  border: 1px solid #d5d3d5;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  color: #6c7a8f;
}

.cnae-no-result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  height: 100%;
}

.cnae-no-result img {
  width: 192px;
  height: 70px;
}

.cnae-no-result p {
  color: #6c7a8f;
  font-size: 12px;
  margin-top: 10px;
}

.top-bar-search ::placeholder {
  letter-spacing: 0px;
  color: #6c7a8f;
  opacity: 0.25;
}

.top-bar-import {
  float: right;
  margin-right: 2.2rem;
  display: flex;
}



.top-bar-import .btn-reserv,
.btn-complement {
  background: #ffffff;
  width: 52px;
  height: 48px;
  margin-left: 10px;
  font-size: 10px;
  font-weight: normal;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  opacity: 0.8;
  color: #307da2;
}

.top-bar-import .btn-import:hover {
  opacity: 1;
  color: #ff5811;
}

.top-bar-import .btn-import,
.btn-complement {
  background: #ffffff;
  width: 48px;
  height: 48px;
  font-size: 10px;
  font-weight: normal;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  opacity: 0.8;
  color: #307da2;
}

.top-bar-import .btn-import:hover {
  opacity: 1;
  color: #ff5811;
}
.top-bar-import .btn-complement .iconPlus:hover {
  opacity: 1;
  color: #ff5811;
}
.iconPlus {
  font-size: 20px;
  color: #307da2;
}

.button-complement {
  margin-right: 10px;
}

.card-no-result {
  margin-top: 60px;
  border-radius: 16px;
}

.no-result {
  padding: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.no-result img {
  width: 142px;
  height: 186px;
}

.no-result .title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01px;
  color: #6c7a8f;
  opacity: 1;
  color: #6c7a8f;
  margin: 0;
}

.no-result .subtitle {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.01px;
  color: #6c7a8f;
  opacity: 1;
  color: #6c7a8f;
}

.card-primary-result {
  margin-top: 60px;
  border-radius: 16px;
}

.primary-result {
  padding: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.primary-result img {
  width: 142px;
  height: 186px;
}

.primary-result .title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01px;
  color: #6c7a8f;
  opacity: 1;
  color: #6c7a8f;
  margin: 0;
}

.card-group {
  margin-top: 65px;
}


.card-empresa .card-body {
  background: #ffffff 0% 0% no-repeat padding-box;
  font-size: 14px;
  color: #6c7a8f;
  border-radius: 7px;
}

.card-body .card-subtitle {
  font-size: 12px;
  color: #6c7a8f;
  line-height: 1.7;
}

.totalDeFiliais {
  text-align: right;
  cursor: pointer;
  font-size: 12px;
  color: #307da2;
}

.enderecoCompleto {
  align: line;
}

.iconCard {
  content: "\f003";
  font-size: 20px;
  text-align: left;
}

.LinksExternos {
  display: inline-flex;
  align-items: baseline;
  font-size: 14px;
  font-weight: normal;
  color: #307da2;
}

.LinksExternos img {
  padding-right: 05px;
}

.button-header {
  margin: 5px;
}

.ButtonSearchMobile {
  display: none;
}

.top-bar-search-mobile {
  display: flex;
  align-content: flex-end;
  float: left;
}
.umCard{
  width: 33%;
}

@media screen and (max-width: 768px) {
  .top-bar-titles-extrato {
    margin-left: 0.2rem;
  }

  .top-bar-import {
    margin-right: 10px;
  }

  .top-bar-extrato {
    display: flex;
    justify-content: space-between;
  }

}

@media screen and (max-width: 575px) {
  .top-bar-search {
    display: none;
  }

  .ButtonSearchMobile {
    display: flex;
    width: 60px;
  }
  .umCard{
    width: 100%;
  }
}
