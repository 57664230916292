.table-bordered td,
.table-bordered th {
  border: none;
  vertical-align: baseline;
}
.fab_del {
  position: fixed;
  bottom: 5rem;
  right: 6.5rem;
}
.fab_del button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: #307da2;
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);

  font-weight: normal;
  color: white;

  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.fab_del :hover {
  color: #ffffff;
  background: #13597b 0% 0% no-repeat padding-box;
  opacity: 1;
}
.fab_select {
  position: fixed;
  bottom: 1rem;
  right: 2.5rem;
}
.fab_select button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: #307da2;
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);

  font-weight: normal;
  color: white;

  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.fab_select :hover {
  color: #ffffff;
  background: #13597b 0% 0% no-repeat padding-box;
  opacity: 1;
}

@media only screen and (max-width: 1080px) {
  .fab_del {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
  .fab_select {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
}
