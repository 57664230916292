.pesquisar {
    width: auto;
    float: left;
}

.pesquisar-botao {
    background   : #ffffff;
    width        : 48px;
    height       : 48px;
    font-size    : 10px;
    font-weight  : normal;
    border       : 1px solid #EBEBEB;
    border-radius: 3px;
    opacity      : 0.8;
    color        : #307DA2;
    text-align   : center;
    margin-right : 10px;
    float        : left;
}


.inputTextSearchMobile {
    padding-left     : 10px;
    height           : 36px;
    width            : 280px;
    color            : #6C7A8F;
    background       : #FFFFFF 0% 0% no-repeat padding-box;
    border           : 1px solid #D5D3D5;
    border-radius    : 0px 4px 4px 0px;
    border-left-style: none;
    opacity          : 1;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    opacity           : 1;
    position          : relative;
    right             : 20px;
    -webkit-appearance: none;
    height            : 20px;
    width             : 20px;
    top               : 05px;
    left              : 5px;
    background        : url('./close.png') no-repeat;
}

.barraPesquisaMobile {
    position    : absolute;
    left: 0;
    width       : 100%;
    height      : 60px;
    background  : #ffffff;
    z-index     : 3;
    margin-right: 240px;
    text-align  : center;
}

.barraPesquisaMobile button {
    top          : 54px;
    left         : 39px;
    width        : 39px;
    height       : 36px;
    border       : 1px solid #D5D3D5;
    border-radius: 4px 0px 0px 4px;
    opacity      : 1;
    background   : #FFFFFF;
}

.barraPesquisaMobile ::placeholder {
    letter-spacing: 0px;
    color         : #6C7A8F;
    opacity       : 0.25;
}